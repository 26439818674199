import {ToastType, toast, setDefaults} from 'bulma-toast';

setDefaults({
  position: 'top-right',
});

const showNotification = (message: string, type: ToastType): void => {
  toast({
    message,
    type,
    dismissible: true,
    duration: type === 'is-danger' ? 30000 : 5000
  });
};

export const showSuccessNotification = (message: string): void => showNotification(message, 'is-success');

export const showErrorNotification = (message: string): void => showNotification(message, 'is-danger');

