import {
  ActiveDltAccountsStatus,
} from '../../api-models/active-dlt-account-status';
import httpService from '../http/http.service';
import {getStartOfDay} from '../../utils/date.utils';

const activeDltAccountService = {
  getStatus(): Promise<ActiveDltAccountsStatus> {
    const url = `/active-dlt-accounts/status`;

    const params = {
      transactionsStartDate: getStartOfDay()
    };

    return httpService.get<ActiveDltAccountsStatus>(url, params);
  }
};

export default activeDltAccountService;
