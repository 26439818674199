import React, {useCallback, useEffect, useState} from 'react';
import AccountStatus from '../components/active-dlt-account/AccountStatus';
import {Columns} from 'react-bulma-components';
import {ActiveDltAccountsStatus} from '../api-models/active-dlt-account-status';
import activeDltAccountService from '../services/active-dlt-account/active-dlt-account.service';

const ActiveDltAccountsPage: React.FC = () => {
  const [accountStatus, setAccountStatus] = useState<ActiveDltAccountsStatus | null>(null);
  const [lastUpdatedAt, setLastUpdatedAt] = useState<Date | null>(null);
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    (async () => {
      setAccountStatus(null);
      setLastUpdatedAt(new Date());

      const status = await activeDltAccountService.getStatus();

      setAccountStatus(status);
    })();
  }, [refreshKey]);

  const refresh = useCallback(() => setRefreshKey((key) => key + 1), []);

  return (
    <Columns>
      <Columns.Column>
        <AccountStatus
          type="payment"
          status={accountStatus?.payment ?? null}
          transactionsStartDate={accountStatus?.transactionsStartDate ?? null}
          lastUpdatedAt={lastUpdatedAt}
          refresh={refresh}
        />
      </Columns.Column>

      <Columns.Column>
        <AccountStatus
          type="dlt"
          status={accountStatus?.dlt ?? null}
          transactionsStartDate={accountStatus?.transactionsStartDate ?? null}
          lastUpdatedAt={lastUpdatedAt}
          refresh={refresh}
        />
      </Columns.Column>
    </Columns>
  );
};

export default ActiveDltAccountsPage;
