import React from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import AccountsPage from './pages/AccountsPage';
import LoginPage from './pages/LoginPage';
import ErrorPage from './pages/ErrorPage';
import ProtectedRoute from './ProtectedRoute';
import {useAuthContext, UserPermissions} from './contexts/auth.context';
import DltTransactionsPage from './pages/DltTransactionsPage';
import ActiveDltAccountsPage from './pages/ActiveDltAccountsPage';

export enum AppRoute {
    TRANSACTIONS = '/dlt-transactions',
    PERSONS = '/persons',
    ACTIVE_DLT_ACCOUNTS = '/active-dlt-accounts',
}

const LOGIN_ROUTE = '/login';

const getDefaultPath = (permission: UserPermissions): AppRoute => {
  if (permission.activeDltAccounts) {
    return AppRoute.ACTIVE_DLT_ACCOUNTS;
  }

  if (permission.transactions) {
    return AppRoute.TRANSACTIONS;
  }

  return AppRoute.PERSONS;
};

const PROTECTED_PAGES: Record<AppRoute, React.ReactElement> = {
  [AppRoute.TRANSACTIONS]: <DltTransactionsPage />,
  [AppRoute.PERSONS]: <AccountsPage />,
  [AppRoute.ACTIVE_DLT_ACCOUNTS]: <ActiveDltAccountsPage />,
};

const AppRoutes: React.FC = () => {
  const auth = useAuthContext();

  const isAuthenticated = auth.user != null;
  const defaultPath = auth.user ? getDefaultPath(auth.user.permissions) : LOGIN_ROUTE;

  return (
    <Routes>
      <Route path={LOGIN_ROUTE} element={<LoginPage />} />
      <Route path="/401" element={<ErrorPage errorText="401 - Access Denied" />} />
      <Route path="/403" element={<ErrorPage errorText="403 - Forbidden" />} />

      {Object.entries(PROTECTED_PAGES).map(([route, element]) => (
        <Route
          key={route}
          path={route}
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              authenticationPath={LOGIN_ROUTE}
            >
              {element}
            </ProtectedRoute>
          }
        />
      ))}

      <Route
        path="/"
        element={<Navigate to={defaultPath} />}
      />

      <Route path="*" element={<ErrorPage errorText="404 - Not found" />} />
    </Routes>
  );
};

export default AppRoutes;
