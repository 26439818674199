import React from 'react';
import {Button, Icon, Navbar} from 'react-bulma-components';
import {NavLink} from 'react-router-dom';
import logo from '../../logo.png';
import {UserPermissions} from '../../contexts/auth.context';
import {FaCircleUser} from 'react-icons/fa6';
import {AppRoute} from '../../AppRoutes';

interface Props {
  username: string;
  permissions: UserPermissions;
  logout: () => void;
}

const renderNavigationLink = (text: string, path: string): React.ReactElement => (
  <Navbar.Item>
    <NavLink
      to={path}
      caseSensitive
      className={({isActive}) => isActive ? 'has-text-primary has-text-weight-bold' : 'has-text-black'}
    >
      {text}
    </NavLink>
  </Navbar.Item>
);

const Header: React.FC<Props> = ({username, permissions, logout}: Props) => {
  return (
    <>
      <Navbar>
        <Navbar.Brand>
          <Navbar.Item
            renderAs={NavLink}
            to='/'
          >
            <img
              style={{maxHeight: '3rem'}}
              src={logo}
            />
          </Navbar.Item>
        </Navbar.Brand>

        <Navbar.Container>
          {permissions.transactions && renderNavigationLink('DLT transactions', '/dlt-transactions')}
          {permissions.persons && renderNavigationLink('Persons', AppRoute.PERSONS)}
          {permissions.activeDltAccounts && renderNavigationLink('Operations', AppRoute.ACTIVE_DLT_ACCOUNTS)}
        </Navbar.Container>

        <Navbar.Container align="right">
          <Navbar.Item renderAs="div">
            <Icon>
              <FaCircleUser />
            </Icon>
            <span className='has-text-weight-bold'>
              {username}
            </span>
          </Navbar.Item>

          <Navbar.Item href="#">
            <Button className="is-ghost px-0" onClick={logout}>
              Logout
            </Button>
          </Navbar.Item>
        </Navbar.Container>
      </Navbar>
    </>
  );
};

export default Header;
