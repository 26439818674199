import React from 'react';
import {Container, Notification} from 'react-bulma-components';

interface Props {
    errorText: string;
}

const ErrorPage: React.FC<Props> = ({errorText}: Props) => {
  return (
    <Container className="mt-6">
      <Notification color="danger">
        {errorText}
      </Notification>
    </Container>
  );
};

export default ErrorPage;
