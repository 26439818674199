import React, {useCallback} from 'react';
import 'bulma/css/bulma.min.css';
import {Block, Container} from 'react-bulma-components';
import AppRoutes from './AppRoutes';
import {useAuthContext} from './contexts/auth.context';
import {history} from './utils/history.utils';
import {unstable_HistoryRouter as HistoryRouter} from 'react-router-dom';
import Header from './components/common/Header';

const App: React.FC = () => {
  const auth = useAuthContext();

  const logout = useCallback(async (): Promise<void> => {
    await auth.logout();
    history.replace('/');
  }, [auth]);

  return (
    <HistoryRouter history={history}>
      <Container>
        {auth.user && (
          <header>
            <Header
              username={auth.user.username}
              permissions={auth.user.permissions}
              logout={logout}
            />
          </header>
        )}

        <Block className="mt-4">
          <AppRoutes />
        </Block>
      </Container>
    </HistoryRouter>
  );
};

export default App;
