import React from 'react';
import {Button, Table} from 'react-bulma-components';
import {SearchDltTransactionsLogItem} from '../../api-models/dlt-transaction';

interface Props {
    readonly items: SearchDltTransactionsLogItem[];
    readonly onItemClicked: (transactionId: string) => void;
}

const DltTransactionsGrid: React.FC<Props> = ({items, onItemClicked}) => {
  const renderRow = (item: SearchDltTransactionsLogItem): React.ReactElement => (
    <tr key={item.transactionId}>
      <td>
        <Button
          color='link'
          inverted
          onClick={() => onItemClicked(item.transactionId)}
        >
          {item.transactionId}
        </Button>
      </td>
      <td>{item.account}</td>
      <td>{item.owner}</td>
      <td>{item.destination}</td>
      <td>{item.authorize}</td>
    </tr>
  );

  return (
    <Table.Container>
      <Table size='narrow'>
        <thead>
          <tr>
            <th>ID</th>
            <th>Account</th>
            <th>Owner</th>
            <th>Destination</th>
            <th>Authorize</th>
          </tr>
        </thead>

        <tbody>
          {items.map(renderRow)}
        </tbody>
      </Table>
    </Table.Container>
  );
};

export default DltTransactionsGrid;
