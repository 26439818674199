import {ActiveDltAccountType, ActiveDltAccountStatus} from '../../api-models/active-dlt-account-status';
import React from 'react';
import './AccountStatus.css';
import GridLoadingIndicator from '../common/GridLoadingIndicator';
import {Button, Card, Columns, Heading, Icon, Tag} from 'react-bulma-components';
import {FaArrowsRotate} from 'react-icons/fa6';
import dayjs from 'dayjs';

interface Props {
    type: ActiveDltAccountType;
    status: ActiveDltAccountStatus | null;
    transactionsStartDate: Date | null;
    lastUpdatedAt: Date | null;
    refresh: () => void;
}

const renderLevelItem = (title: string, value: string | number): React.ReactElement => (
  <Columns.Column className="stat-info">
    <p className="heading">{title}</p>
    <p className="title">{value}</p>
  </Columns.Column>
);

const AccountStatus: React.FC<Props> = ({type, status, lastUpdatedAt, refresh, transactionsStartDate}) => {
  const isLoading = status == null;

  return (
    <Card>
      <Card.Content>
        <Columns className="mb-6 is-gapless is-align-items-center">
          <Columns.Column>
            <Heading
              className="is-uppercase"
              mb="0"
            >
              {type}
            </Heading>

            <p
              className="is-size-6 has-text-grey has-text-weight-medium"
            >
              {status?.address}
            </p>
          </Columns.Column>

          <Columns.Column narrow>
            <div className="is-flex is-justify-content-end">
              <Button disabled={isLoading} color="white" onClick={refresh}>
                <Icon>
                  <FaArrowsRotate />
                </Icon>
              </Button>
            </div>

            <div>
              <Tag>{lastUpdatedAt?.toLocaleString()}</Tag>
            </div>
          </Columns.Column>
        </Columns>

        {isLoading && <GridLoadingIndicator />}

        {!isLoading &&
          <Columns>
            {renderLevelItem('Balance', status?.balance)}
            {renderLevelItem('Limit', status?.limit)}
            {renderLevelItem('XRP', status?.xrpBalance)}
            {renderLevelItem('Sequence', status?.sequence)}
            {status?.transactionsCount != null && renderLevelItem(`Transactions from ${dayjs(transactionsStartDate).toDate().toLocaleString()}`, status.transactionsCount)}
          </Columns>
        }
      </Card.Content>
    </Card>
  );
};

export default AccountStatus;
