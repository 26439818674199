import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {parseJwt} from '../utils/jwt.utils';
import httpService from '../services/http/http.service';
import {env} from '../env';
import {ApiRoles} from '../constants/roles.constants';

export interface UserPermissions {
  persons: boolean;
  transactions: boolean;
  activeDltAccounts: boolean;
}

interface CurrentUser {
  userId: string;
  username: string;
  roles: ApiRoles[];
  permissions: UserPermissions;
}

interface AuthContextType {
    user?: CurrentUser;
    login: (phoneNumber: string, code: string) => Promise<void>;
    logout: () => Promise<void>;
}

const toPermissions = (userRoles: ApiRoles[]): UserPermissions => {
  const hasPermission = (...roles: ApiRoles[]): boolean => roles.every((role) => userRoles.includes(role));

  return {
    persons: hasPermission(ApiRoles.ADMIN),
    transactions: hasPermission(ApiRoles.ADMIN),
    activeDltAccounts: hasPermission(ApiRoles.ADMIN),
  };
};

const AuthContext = createContext<AuthContextType>({} as AuthContextType);
export const useAuthContext = () => useContext(AuthContext);

export const AuthContextProvider: React.FC = ({children}) => {
  const [user, setUser] = useState<CurrentUser>();
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);

  const setUserFromJwt = (token: string | null): void => {
    if (token == null) {
      return;
    }

    const decodedJwt = parseJwt(token);
    if (decodedJwt == null) {
      throw new Error('Failed to parse user token');
    }

    if (decodedJwt.exp * 1000 > Date.now()) {
      setUser({
        userId: decodedJwt.userId,
        username: decodedJwt.username,
        roles: decodedJwt.roles,
        permissions: toPermissions(decodedJwt.roles)
      });
    }
  };

  useEffect(() => {
    const jwtToken = localStorage.getItem('jwt');
    setUserFromJwt(jwtToken);

    setLoadingInitial(false);
  }, []);

  const logout = async (): Promise<void> => {
    localStorage.removeItem('jwt');

    setUser(undefined);
  };

  const login = useCallback(async (username: string, password: string): Promise<void> => {
    const data = await httpService.post<{accessToken: string}>(`${env.acsAuth.url}/auth/token`, {
      username: username.trim(),
      password: password.trim(),
    });

    if (data != null) {
      localStorage.setItem('jwt', data.accessToken);

      setUserFromJwt(data.accessToken);
    }
  }, []);

  const memoedValue = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    [login, user],
  );

  return (
    <AuthContext.Provider value={memoedValue}>
      {!loadingInitial && children}
    </AuthContext.Provider>
  );
};
