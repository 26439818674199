import {ApiRoles} from '../constants/roles.constants';

export interface DecodedToken {
  exp: number;
  userId: string;
  username: string;
  roles: ApiRoles[];
}

export const parseJwt = (token: string): DecodedToken | null => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};
